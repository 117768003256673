// @flow
import React, {
  type StatelessFunctionalComponent, useState,
} from "react";
import {
  Box, Button, TextField, Typography,
} from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import { makeStylesTyped } from "@fas/cpa-cabinet-ui/lib/helpers";
import { login } from "../../services/request";

type Props = {
  onNext: ({expires: number, token: string}) => mixed,
}

const useStyles = makeStylesTyped((theme) => ({
  input: {
    paddingBottom: theme.spacing(2),
  },
}));

const AuthField: StatelessFunctionalComponent<Props> = ({ onNext }: Props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    login: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((data) => ({ ...data, [name]: value }));
    setErrorMessage("");
  };

  const onKeyPress = ({ charCode }: { charCode: number }) => {
    const enterCharCode: number = 13;
    if (charCode === enterCharCode) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    login(formData).then((r) => {
      onNext(r.data.payload);
    }).catch((r) => {
      const message: string = (r && r.response && r.response.data && r.response.data.message) || "Error";
      setErrorMessage(message);
      setLoading(false);
    });
  };

  return (
    <Box onKeyPress={onKeyPress}>
      <Typography variant="body2">
        User Name
      </Typography>
      <TextField
        variant="outlined"
        margin="dense"
        required
        fullWidth
        autoFocus
        placeholder="Input your User Name"
        size="small"
        type="text"
        name="login"
        value={formData.login}
        onChange={handleChange}
        className={classes.input}
        error={Boolean(errorMessage)}
        data-testid="login"
      />
      <Typography variant="body2">
        Password
      </Typography>
      <TextField
        variant="outlined"
        margin="dense"
        required
        fullWidth
        size="small"
        placeholder="Input your password"
        type="password"
        name="password"
        value={formData.password}
        onChange={handleChange}
        className={classes.input}
        error={Boolean(errorMessage)}
        helperText={errorMessage}
        data-testid="password"
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        name="next"
        onClick={handleSubmit}
        endIcon={<KeyboardArrowRight />}
        disabled={loading}
        data-testid="button-next"
      >
        <Box width="100%">Next</Box>
      </Button>
    </Box>
  );
};

export default AuthField;
