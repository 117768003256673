// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent, useEffect } from "react";
import {
  Box, Button, CircularProgress, Grid, Typography,
} from "@mui/material";
import { FilterBuilder } from "@fas/cpa-cabinet-ui";
import type { Filters } from "@fas/cpa-state-manager/redux/actions/table/actions";
import dayjs from "dayjs";
import en from "dayjs/locale/en-gb";
import GetApp from "@mui/icons-material/GetApp";
import { makeStylesTyped } from "@fas/cpa-cabinet-ui/lib/helpers";
import type { Column } from "../TableComponent/types/TableComponent.types";
import { TRANSACTION_TABLE } from "../../helpers/constants";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import { TableComponent, TablePagination } from "../TableComponent";
import type { ItemData, TotalData } from "../../services/dashboardApi";
import { mapOptions, presets } from "../../helpers/generators";
import { setQueryFilter } from "../../helpers/queryFilter";

const FilterWrapper: StatelessFunctionalComponent<*> = ({ children }) => (
  <Box display="inline-block" pr={1} pb={1}>{children}</Box>
);

type Props = {|
  data: ItemData[],
  totalData: TotalData,
  page: number,
  total: number,
  pageSize: number,
  filters: Filters,
  loading: boolean,
  onChangeTablePage: (string, number) => mixed,
  onChangeTableFilters: (string, Filters) => mixed,
  onChangeTablePageSize: (string, number) => mixed,
  onGetData: () => mixed,
  onDownloadTransactionReport: () => mixed,
  departmentsDropdown: DropDownObjItemType[],
  countriesDropdown: DropDownObjItemType[],
  customersDropdown: DropDownObjItemType[],
  platformsDropdown: DropDownObjItemType[],
  agesDropdown: DropDownObjItemType[],
  onGetCountriesDropdownData: () => mixed,
  onGetPlatformsDropdownData: () => mixed,
  onGetAgesDropdownData: () => mixed,
  setIsAdvancedFilterEnabled: (boolean) => mixed,
  isAdvancedFilterEnabled: boolean,
  fields: string[],
|}

const useStyles = makeStylesTyped((theme) => ({
  input: {
    width: "180px",
  },
  dateRange: {
    width: "253px",
    "& textarea": {
      textAlign: "center",
    },
    "& input": {
      height: "1.1876em",
    },
  },
  label: {},
  download: {
    width: "320px",
  },
  downloadIcon: {
    marginLeft: "60px",
    marginRight: "-80px",
  },
  headerCell: {
    color: theme.palette.primary.main,
  },
}));

const TransactionReport: StatelessFunctionalComponent<Props> = ({
  data,
  totalData,
  page,
  total,
  pageSize,
  filters,
  loading,
  onChangeTablePage,
  onChangeTableFilters,
  onChangeTablePageSize,
  onGetData,
  onDownloadTransactionReport,
  departmentsDropdown,
  countriesDropdown,
  customersDropdown,
  platformsDropdown,
  agesDropdown,
  onGetCountriesDropdownData,
  onGetPlatformsDropdownData,
  onGetAgesDropdownData,
  setIsAdvancedFilterEnabled,
  isAdvancedFilterEnabled,
  fields,
}: Props) => {
  const classes = useStyles();

  useEffect(() => {
    if (data.length === 0) {
      onGetData();
    }
  }, []);

  useEffect(() => {
    onGetCountriesDropdownData();
    onGetPlatformsDropdownData();
    onGetAgesDropdownData();
  }, []);

  useEffect(() => {
    setQueryFilter(filters);
  }, [filters]);

  const columns: Array<Column<ItemData>> = [
    {
      label: "Date",
      field: "date",
      key: "date",
    },
    {
      label: "Company Name",
      field: "customer",
      key: "customer",
    },
    {
      label: "Transaction Id",
      field: "invoiceId",
      key: "invoiceId",
    },
    {
      label: "Department",
      field: "department",
      key: "department",
    },
    {
      label: "Country",
      field: "country",
      key: "country",
    },
    {
      label: "Platform",
      field: "platform",
      key: "platform",
    },
    {
      label: "Age Group",
      field: "age",
      key: "age",
    },
    {
      label: "Leads",
      field: "quantity",
      key: "quantity",
    },
    {
      label: "Commission",
      field: "cost",
      key: "cost",
    },
    {
      label: "Total Commission",
      field: "amount",
      key: "amount",
    },
  ].filter((column: Column<ItemData>): boolean => fields.includes(column.field));

  const totalColumns: Array<Column<TotalData>> = [
    {
      label: "",
      field: "",
      key: "summary",
      render: (): string => "Summary",
    },
    {
      label: "",
      field: "",
      key: "quantity",
      render: ({ row }: { row: TotalData }): string => `${String(filters.currency) || "$"} ${row.amount}`,
      colSpan: columns.length - 1,
    },
  ];

  const isShowData: boolean = data.length > 0 && !loading;
  const isShowNoDataMessage: boolean = data.length === 0 && !loading;

  const handleFiltersChange: (Filters) => void = (newFilters) => {
    onChangeTableFilters(TRANSACTION_TABLE, newFilters);
  };

  const handlePageChange: (number) => void = (newPage) => {
    onChangeTablePage(TRANSACTION_TABLE, newPage);
    onGetData();
  };

  const handlePageSizeChange: (number) => void = (newPageSize) => {
    onChangeTablePage(TRANSACTION_TABLE, 1);
    onChangeTablePageSize(TRANSACTION_TABLE, newPageSize);
    onGetData();
  };

  const handleRangeChangeDateRangePicker = ({ startDate, endDate }) => {
    onChangeTableFilters(TRANSACTION_TABLE, { dateFrom: startDate, dateTo: endDate });
  };

  const dynamicFieldsOptions: DropDownObjItemType[] = [
    { value: "customer", label: "Company Name" },
  ];
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box width={1} pb={2} display="flex" justifyContent="space-between">
          <Typography variant="h5">Transaction Report</Typography>
          <Box>
            <Button
              className={classes.download}
              variant="contained"
              color="secondary"
              name="download"
              disabled={loading}
              endIcon={<GetApp className={classes.downloadIcon} />}
              onClick={onDownloadTransactionReport}
              data-testid="download"
            >
              Download CSV
            </Button>
          </Box>
        </Box>
        <Box display="flex">
          <Box flexGrow={1}>
            <FilterBuilder
              data-testid="filters"
              WrapperItemComponent={FilterWrapper}
              // $FlowFixMe
              filters={filters}
              // $FlowFixMe
              onFiltersChange={handleFiltersChange}
              items={[
                {
                  type: "select",
                  filterKey: "department",
                  filterProps: {
                    label: "Department",
                    className: classes.input,
                    classes: { formLabel: classes.label },
                    disabled: loading,
                    "data-testid": "filter-department",
                    options: [
                      { value: "", label: "All" },
                      ...departmentsDropdown,
                    ].map(mapOptions),
                  },
                },
                {
                  type: "select",
                  filterKey: "country",
                  filterProps: {
                    label: "Country",
                    className: classes.input,
                    classes: { formLabel: classes.label },
                    disabled: loading,
                    "data-testid": "filter-country",
                    options: [
                      { value: "", label: "All" },
                      ...countriesDropdown,
                    ].map(mapOptions),
                  },
                },
                {
                  type: "select",
                  filterKey: "customer",
                  filterProps: {
                    label: "Company Name",
                    className: classes.input,
                    classes: { formLabel: classes.label },
                    disabled: loading,
                    "data-testid": "filter-customer",
                    options: [
                      { value: "", label: "All" },
                      ...customersDropdown,
                    ].map(mapOptions),
                  },
                },
                {
                  type: "textField",
                  filterKey: "invoiceId",
                  filterProps: {
                    label: "Transaction id",
                    className: classes.input,
                    classes: { formLabel: classes.label },
                    disabled: loading,
                    type: "number",
                    "data-testid": "filter-invoiceId",
                  },
                },
                {
                  type: "select",
                  filterKey: "platform",
                  filterProps: {
                    label: "Platform",
                    className: classes.input,
                    classes: { formLabel: classes.label },
                    disabled: loading,
                    "data-testid": "filter-platform",
                    options: [
                      { value: "", label: "All" },
                      ...platformsDropdown,
                    ].map(mapOptions),
                  },
                },
                {
                  type: "select",
                  filterKey: "age",
                  filterProps: {
                    label: "Age Group",
                    className: classes.input,
                    classes: { formLabel: classes.label },
                    disabled: loading,
                    "data-testid": "filter-age",
                    options: [
                      { value: "", label: "All" },
                      ...agesDropdown,
                    ].map(mapOptions),
                  },
                },
                {
                  type: "dateRangePicker",
                  filterKey: "dateRangePicker",
                  filterProps: {
                    disabled: loading,
                    classes: { input: { root: classes.dateRange }, formLabel: classes.label },
                    label: "Date range",
                    startDate: filters.dateFrom,
                    endDate: filters.dateTo,
                    onChangeDate: handleRangeChangeDateRangePicker,
                    onChangePreset: handleRangeChangeDateRangePicker,
                    presets,
                    locale: en,
                    formatDate: (date) => dayjs(date).format("DD-MM-YYYY"),
                    "data-testid": "filter-date-range-picker",
                  },
                },
              ]}
            />
            { isAdvancedFilterEnabled && (
              <Box>
                <FilterBuilder
                  WrapperItemComponent={FilterWrapper}
                  // $FlowFixMe wrong type
                  filters={filters}
                  onFiltersChange={handleFiltersChange}
                  items={[
                    {
                      type: "select",
                      filterKey: "currency",
                      filterProps: {
                        label: "Conversion currency",
                        className: classes.input,
                        classes: { formLabel: classes.label },
                        disabled: loading,
                        "data-testid": "filter-currency",
                        disableClearable: true,
                        options: [
                          { value: "$", label: "Only in $" },
                          { value: "€", label: "Only in €" },
                          { value: "£", label: "Only in £" },
                        ].map(mapOptions),
                      },
                    },
                    {
                      type: "multiSelect",
                      filterKey: "dynamicFields",
                      filterProps: {
                        label: "Show columns",
                        className: classes.dateRange,
                        classes: { formLabel: classes.label },
                        disabled: loading,
                        "data-testid": "filter-dynamicFields",
                        options: dynamicFieldsOptions.map(mapOptions),
                      },
                    },
                  ]}
                />
              </Box>
            )}
          </Box>
          <Box alignSelf="flex-start" display="flex" pt={2} flexShrink={0}>
            <Box pr={1}>
              <Button
                variant="contained"
                color={isAdvancedFilterEnabled ? "primary" : "secondary"}
                name="advancedFilter"
                onClick={() => {
                  setIsAdvancedFilterEnabled(!isAdvancedFilterEnabled);
                }}
              >
                Advanced filter
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                name="applyFilters"
                disabled={loading}
                onClick={onGetData}
              >
                Apply filters
              </Button>
            </Box>
          </Box>
        </Box>
        { loading && (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
        { isShowNoDataMessage && (
          <Grid container justifyContent="center">
            <Typography>Nothing to show</Typography>
          </Grid>
        )}
        { isShowData && (
          <>
            <TableComponent
              classes={{ headerCell: classes.headerCell }}
              data={data}
              columns={columns}
              totalData={totalData}
              totalColumns={totalColumns}
            />
            <TablePagination
              data-testid="pagination"
              page={page}
              pageSize={pageSize}
              count={total}
              onChangePage={handlePageChange}
              onChangePageSize={handlePageSizeChange}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default TransactionReport;
